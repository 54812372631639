import React from 'react';
import {withRouter, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'

import Cookies from 'universal-cookie'

import './project.container.styles.sass'
import actions from "../../actions"
import ProjectComponent from "../../components/project/";


const cookies = new Cookies();


class ProjectContainer extends React.Component {
    componentDidMount() {
        if (this.props.groups.length === 0)
            this.props.loadProjects(this.props.keyLogin, this.props.keyAccess)
    }

    render() {
        const params = {
            projeto: this.props.match.params.projeto.split('_').join(" "),
            categoria: this.props.match.params.categoria.split('_').join(" ")
        };

        if (this.props.groups[0] !== undefined) {
            const group = this.props.groups.find(group => group.projetos[0].categoriaUrl === params.categoria);
            const projeto = group.projetos.find(project => project.nomeUrl.toLowerCase() === params.projeto);

            return this.props.auth ? (
                <div className='project-container'>
                    <ProjectComponent group={group} projeto={projeto} {...this.props}/>
                </div>
            ) : (
                <Redirect to="/entrar"/>
            )

        } else {
            return <div className='project-container'>
                {!this.props.auth ? (
                    <Redirect to="/entrar"/>
                ) : undefined}
            </div>;
        }
    }
}

const mapDispatchToProps = dispatch => ({
    setProjectVote: (group, projeto, voto) => {
      dispatch(actions.setProjectVote(group, projeto, voto));
    },
    loadProjects: (keyLogin, keyAccess) => {
        dispatch(actions.loadProjects(keyLogin, keyAccess));
    },
    sendVote: (keyLogin, keyAcess, codigoProjeto, criterios, group, projeto) => {
        dispatch(actions.sendVote(keyLogin, keyAcess, codigoProjeto, criterios, group, projeto));
    },
    toggleModal: (group, project, status) => {
        dispatch(actions.toggleModal(group, project, status))
    }
});
const mapStateToProps = (state) => ({
    voteFetched: state.ui.votes.fetched,
    voteFetching: state.ui.votes.fetching,
    voteError: state.ui.votes.error,
    criterios: state.data.criterios,
    auth: state.control.auth,
    groups: state.data.groups,
    keyAccess: state.control.key,
    keyLogin: cookies.get("keyLogin") !== undefined ? cookies.get("keyLogin") : null,
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectContainer));
