const defaultState = {
    control: {
        auth: false,
        key: 'oIbfygBUibYcdbNfPsG64tPH80',
        validated: false
    },
    data: {
        groups: [],
        user: {
            name: "Usuário"
        },
        criterios: [
            {
                label: "Inovação",
                text: "Desenvolvimento de novas abordagens, soluções disruptivas e/ou alternativas em relação aos produtos, serviços e/ou práticas que tiveram/terão um benefício significativo para a área. "
            },
            {
                label: " Eficiência/Produtividade",
                text: "Projetos que tiveram ganhos expressivos de eficiência com base em ações relacionadas à redução de custos, incremento de receita e/ou à melhoria contínua, visando, inclusive, à redução da burocracia e da complexidade nas operações e processos internos."
            },
            {
                label: "Excelência em Serviços",
                text: "Alinhamento às diretrizes e estratégias da empresa buscando excelência na prestação de serviços e atendimento, que promove o respeito em relação à marca."
            },
            {
                label: "Foco no cliente",
                text: "O projeto atende as necessidades dos clientes (interno/externo) de uma forma direta ou indireta, que os surpreenda positivamente, oferecendo uma solução que supera suas expectativas."
            },
            {
                label: "Pilares de Sustentabilidade",
                text: "Alinhamento com os pilares da nova diretriz mundial de sustentabilidade com o objetivo de desenvolver um negócio economicamente viável, socialmente justo e ambientalmente correto que gere valor para os nossos principais públicos de interesse."
            },
      
        ]
    },
    ui: {
        app: {
            fetching: false,
            error: false
        },
        login: {
            fetching: false,
            fetched: false,
            success: false
        },
        votes: {
            projectCode: null,
            fetching: false,
            error: false,
            fetched: false
        }
    }
};

export default defaultState
