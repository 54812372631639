import React from 'react';
import {useWindowDimensions} from '../../utils'

const FullWidthContainer = (props) => {
    const { width } = useWindowDimensions();

    const mainContainer = {
        width: '100vw',

        left: width > props.containerWidth ? `calc((${props.containerWidth}px - 100vw)/2)` : '0',
        position: 'relative'
    };

    const childContainer = {
        width: '100vw',
        maxWidth: `${props.containerWidth}px`,
        margin: 'auto',
        position: 'relative'
    };

    return (
        <div className={`${props.className}-parent`} style={mainContainer}>
            <div className={props.className} style={childContainer}>
                {props.children}
            </div>
        </div>
    )
};

export default FullWidthContainer