import React from 'react';
import {withRouter, Redirect} from 'react-router-dom'
import { connect } from 'react-redux'
import actions from '../../actions'
import Cookies from 'universal-cookie'

import Loader from "react-loader-spinner";

import './projects.container.styles.sass'
import ProjectsComponent from "../../components/projects/";

const cookies = new Cookies();

class ProjectsContainer extends React.Component {
    componentDidMount() {
        if(this.props.groups.length === 0){
            this.props.loadProjects(this.props.keyLogin, this.props.keyAccess)
        }
    }
    render() {
        return this.props.auth === true ? (
            <div className='projects-container'>
                <ProjectsComponent {...this.props} />
            </div>
        ) : (
            <Redirect to="/entrar"/>
        )

    }
}

const mapDispatchToProps = dispatch => ({
    loadProjects: (keyLogin, keyAccess) => {
        dispatch(actions.loadProjects(keyLogin, keyAccess));
    },
    sendVote: (keyLogin, keyAcess, codigoProjeto, criterios, group, projeto) => {
        dispatch(actions.sendVote(keyLogin, keyAcess, codigoProjeto, criterios, group, projeto));
    },
    setProjectVote: (group, projeto, voto) => {
        dispatch(actions.setProjectVote(group, projeto, voto));
    },
    toggleModal: (group, project, status) => {
        dispatch(actions.toggleModal(group, project, status))
    }
});

const mapStateToProps = (state) => ({
    voteFetched: state.ui.votes.fetched,
    voteFetching: state.ui.votes.fetching,
    voteError: state.ui.votes.error,
    criterios: state.data.criterios,
    auth: state.control.auth,
    groups: state.data.groups,
    keyAccess: state.control.key,
    keyLogin: cookies.get("keyLogin") !== undefined ? cookies.get("keyLogin") : null,
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsContainer));
