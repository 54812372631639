import React from 'react';
import {connect} from 'react-redux'
import {withRouter, Redirect} from 'react-router-dom'

class HomePage extends React.Component {
    render() {
        //console.log(this.props)
        return (
            <div className="home-page-container">
                {this.props.auth === true ? (
                    <Redirect to="/projetos" />
                ) : (
                    <Redirect to="/entrar" />
                )}
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = (state) => ({
    auth: state.control.auth
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage));