import React, {useState} from 'react'
import { InputComponent, ButtonComponent } from '../utils'
import Loader from 'react-loader-spinner'

import './login.component.styles.sass'

const LoginComponent = function (props) {

    const [values, setValues] = useState({user: "", pass: ""});

    const handleInputChange = e => {
        const {name, value} = e.target;
        setValues({...values, [name]: value})
    };

    const handleSubmit = e => {
        e.preventDefault();
        props.requestLogin(values.user,values.pass, props.keyAccess)
    };

    return (
        <div key="login" className='login-container'>
            <h1>
                Login
                <span style={{color:'#202856', marginLeft: 5}}>
                EWARD BRASIL
            </span>
            </h1>
            <form autoComplete="off" onSubmit={e => handleSubmit(e)}>

                <InputComponent
                    type='text'
                    value={values.user === null ? "" : values.use}
                    autoComplete={values.user === null ? "" : values.user}
                    onChange={handleInputChange}
                    placeholder='Usuário'
                    name='user'
                />

                <InputComponent
                    type='password'
                    value={values.pass === null ? "" : values.pass}
                    autoComplete={values.pass === null ? "" : values.pass}
                    onChange={handleInputChange}
                    placeholder='Senha'
                    name='pass'
                />

                <ButtonComponent
                    fetched={props.fetched}
                    fetching={props.fetching}
                    success={props.success}
                    style={{
                        backgroundColor: props.fetching === true ?  "#C1D532" : "#162056"
                    }}
                    type='submit'
                >
                    {props.fetching === true ? (
                        <Loader
                            type="ThreeDots"
                            color="#f2f2f2"
                            height={20}
                            width={45}
                        />
                    ) : (
                        "Entrar"
                    )}
                </ButtonComponent>

            </form>
        </div>
    );
};

export default LoginComponent