import React from 'react';
import styled from 'styled-components';
import {FaExclamationTriangle} from "react-icons/fa";

const StyledButton = styled.button`
        color: #fff
        background: #162056
        padding: 9px 30px
        border-radius: 30px
        border: 0
        font-size: 15px
        font-family: 'century gothic bold", sans-serif'
        font-weight: 600
        transition: 250ms linear all
        cursor: pointer
        :hover {
            background: #C1D532
        }
        :-webkit-autofill,
        :-webkit-autofill:hover, 
        :-webkit-autofill:focus, 
        :-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
    `;

const MessageDiv = styled.div`
    background: tomato
    color: #f2f2f2
    border-radius: 15px
    padding: 9px 30px
    display:flex
    justify-content: space-between
    align-items: center
`;

const ButtonComponent = (props) => {

    const StyledDiv = styled.div`
        display:flex
        justify-content: space-between
        align-items: center
        font-size: 11px
    `;

    return (
        <StyledDiv>
            <MessageDiv style={{
                display: props.fetched === false && props.success === false ? "none" : "flex"
            }}>
                <FaExclamationTriangle style={{marginRight: 10, fontSize: 15}}/>
                {props.fetched === true && props.success === true ?
                    "Usuário e/ou senha inválidos." :
                    props.fetched === true && props.success === false
                        ? "Erro de conexão."
                        : undefined
                }
            </MessageDiv>
            <div></div>
            <StyledButton {...props}>
                {props.children}
            </StyledButton>
        </StyledDiv>
    )
};

export default ButtonComponent