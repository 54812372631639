import React from 'react'
import './projects.component.styles.sass'
import { HalfWidthContainer } from '../utils'
import _ from 'lodash'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import './tabs.styles.sass'
import ProjectListComponent from "../project-list/";

const ProjectsComponent = (props) => {
    const sortedGroups = _.sortBy(props.groups, 'type');


    return (
        <div className='projects-component'>
            <HalfWidthContainer direction="left" containerWidth={1200} className="projects-h1">
                <h1>
                    Projetos:
                </h1>
            </HalfWidthContainer>
            <div style={{padding:'0 15px'}}>
                <Tabs defaultIndex={props.defaultIndex}>
                    <TabList>
                        {sortedGroups.map((group, key) => (
                            <Tab onClick={() => props.setDefaultIndex(key)} key={key}>{group.type}</Tab>
                        ))}
                    </TabList>
                    {sortedGroups.map((group, key) => {
                        const sortedProjects = _.sortBy(group.projetos, 'pro_ordem');

                        return (
                            <TabPanel key={key}>
                                <ul className='projects-list'>
                                    {sortedProjects.map((projeto, key) => <ProjectListComponent key={key} group={group} projeto={projeto} {...props}/>)}
                                </ul>
                            </TabPanel>
                        )
                    })}
                </Tabs>
            </div>
        </div>
    )
};

export default ProjectsComponent
