import React from 'react';
import ReactDOM from 'react-dom';

import store from './store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom' // react-router v4/v5

import './reset.sass'
import './index.sass'

import { AppContainer } from "./components";

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <AppContainer/>
        </Router>
    </Provider>,
    document.getElementById('root')
);
