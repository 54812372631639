import React from 'react'
import {HalfWidthContainer} from '../../components/utils';
import { groupArr } from '../../utils'
import { SkyLightStateless } from 'react-skylight';
import { Link } from 'react-router-dom'
import {FaHome, FaClipboardList} from "react-icons/fa"
import './project.component.styles.sass'
import VoteContainer from "../../containers/vote/";

const ProjectComponent = (props) => {
    console.log(props)
    return (
        <div className='project-component'>
            <div className='main-h1'>
                <HalfWidthContainer containerWidth={1200} direction={"left"} className="h1-azul">
                    <h1>
                        Nome do Projeto: {props.projeto.pro_nome.replace(/<[^>]*>?/gm, '')}
                    </h1>
                </HalfWidthContainer>
            </div>

            <HalfWidthContainer containerWidth={1200} direction={"left"} className="container-cinza">
                <div className="cols">
                    <div>
                        Data de realização do projeto:
                    </div>
                    <div>
                        Início: {props.projeto.pro_datainicio.split("-").join("/").replace(/<[^>]*>?/gm, '')}
                    </div>
                    <div>
                        Fim: {props.projeto.pro_datafim.split("-").join("/").replace(/<[^>]*>?/gm, '')}
                    </div>
                </div>
            </HalfWidthContainer>
            {/*
            <div className='project-inscr'>
                <HalfWidthContainer containerWidth={1200} direction={"left"} className="h1-azul">
                    <h1> Dados da área de inscrição do projeto</h1>
                </HalfWidthContainer>

                <div className='cols'>
                    <div>
                        <HalfWidthContainer containerWidth={600} direction={"left"} className="h1-verde-left">
                            <h1>
                                Nome <br/>
                                da diretoria: {props.projeto.pro_diretoria.replace(/<[^>]*>?/gm, '')}
                            </h1>
                        </HalfWidthContainer>
                    </div>
                    <div>
                        <HalfWidthContainer containerWidth={600} direction={"right"} className="h1-verde-right">
                            <h1>
                                Diretor <br/>
                                responsável: {props.projeto.pro_diretorresponsavel.replace(/<[^>]*>?/gm, '')}
                            </h1>
                        </HalfWidthContainer>
                    </div>
                </div>
            </div>*/}


            <div className="project-team">
                <HalfWidthContainer containerWidth={1200} direction={"left"} className="h1-azul">
                    <h1> Equipe: </h1>
                </HalfWidthContainer>
                {groupArr(props.projeto.pro_equipe.split(", "), 2).map((membros, key) => (
                    <div className='cols' key={key}>
                        <div>
                            <p>
                                {membros[0] !== undefined ? membros[0].replace(/<[^>]*>?/gm, '') : null}
                            </p>
                        </div>
                        <div>
                            <p>
                                {membros[1] !== undefined ? membros[1].replace(/<[^>]*>?/gm, '') : null}
                            </p>
                        </div>
                    </div>
                ))}
            </div>

            <div className='project-data'>
                <div className="project-data-header">
                    <HalfWidthContainer containerWidth={1200} direction={"left"} className="h1-left-verde-escuro">
                        <h1 style={{
                            fontSize:36,
                            color:"#fff"
                        }}>Dados do projeto:</h1>
                    </HalfWidthContainer>
                </div>
                <div className='cols'>
                    <div>
                        <HalfWidthContainer containerWidth={600} direction={"left"} className="h1-verde-left">
                            <h1>Descritivo</h1>
                        </HalfWidthContainer>
                        <p>
                            {props.projeto.pro_desafio.replace(/<[^>]*>?/gm, '')}
                        </p>
                    </div>
                    <div>
                        <HalfWidthContainer containerWidth={600} direction={"right"} className="h1-right-verde-escuro">
                            <h1>Resultados</h1>
                        </HalfWidthContainer>
                        <p>
                            {props.projeto.pro_foco.replace(/<[^>]*>?/gm, '')}
                        </p>
                    </div>
                </div>

            </div>

            <div className="project-actions">
                <div className="cols">
                    <div>
                        <button onClick={() => {props.toggleModal(props.group, props.projeto, true)}}>
                            <FaClipboardList style={{marginRight: '10px'}}/>
                            Votar
                        </button>
                    </div>
                    <div>
                        <Link style={{textDecoration:"none"}} to='/inicio'>
                            <button>
                                <FaHome style={{marginRight: '10px'}}/>
                                Início
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div>
                <SkyLightStateless
                    hideOnOverlayClicked={true}
                    closeButtonStyle={{
                        color:'#78be2d',
                        fontWeight: 'bold',
                        padding: 10,
                        fontSize: 36
                    }}
                    dialogStyles={{
                        color: '#727176',
                        width: '90%',
                        maxHeight: '92%',
                        padding: '15px 45px',
                        top: 0,
                        marginTop: 30,
                        marginLeft: '-45%',
                        minHeight: "0"
                    }}
                    isVisible={props.projeto.modal}
                    onCloseClicked={() => {props.toggleModal(props.group, props.projeto, false)}}
                >
                   <VoteContainer {...props}/>
                </SkyLightStateless>
            </div>
        </div>
    );
};

export default ProjectComponent
