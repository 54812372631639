import React, {useState} from 'react';
import {Col, Container, Row, setConfiguration} from "react-grid-system"
import { Collapse } from 'react-collapse'
import { RadioButton, RadioGroup } from 'react-radio-group-context'
import './criteria.component.styles.sass'

import { MdInfoOutline } from 'react-icons/md'

setConfiguration({gridColumns: 10 });

const CriteriaComponent = (props) => {
    const { itemSelected, setProjectVote, group, projeto, objectKey } = props;

    const [collapse, toggleCollapse] = useState(false);

    const handleChange = ({ target: { value } }) => {
        setProjectVote(group, projeto, {
            objectKey,
            value
        });
    };

    return (
        <Container className='criteria-component' fluid>
            <Row>
                <Col lg={2}>
                    <h1 onClick={() => {
                        toggleCollapse(!collapse);
                    }}>
                        {props.label}
                        <MdInfoOutline style={{marginLeft: 5, fontSize: 24, color: '#666'}}/>
                    </h1>
                    <div className="collapse-container">
                        <Collapse isOpened={collapse}>
                            <div style={{fontSize: 13, paddingBottom: 15}}>{props.text}</div>
                        </Collapse>
                    </div>
                </Col>
                <Col lg={8} className={"row-ref"}>
                    <RadioGroup
                        name="votes"
                        selected={itemSelected}
                        onChange={handleChange}
                    >
                            <Row >
                                <Col lg={2}>
                                    <RadioButton
                                        className={
                                            itemSelected === "0" ?
                                            "active"
                                            : undefined
                                        }
                                        id={"0"}
                                    >
                                        Não se aplica
                                    </RadioButton>
                                </Col>
                                <Col lg={2}>
                                    <RadioButton
                                        className={
                                            itemSelected === '1' ?
                                                "active"
                                                : undefined
                                        }
                                        id="1"
                                    >
                                        Não atende
                                    </RadioButton>
                                </Col>
                                <Col lg={2}>
                                    <RadioButton
                                        className={
                                            itemSelected === '2' ?
                                                "active"
                                                : undefined
                                        }
                                        id="2"
                                    >
                                        Atende parcial
                                    </RadioButton>
                                </Col>
                                <Col lg={2}>
                                    <RadioButton
                                        className={
                                            itemSelected === '3' ?
                                                "active"
                                                : undefined
                                        }
                                        id="3"
                                    >
                                        Atende
                                    </RadioButton>
                                </Col>
                                <Col lg={2}>
                                    <RadioButton
                                        className={
                                            itemSelected === "4" ?
                                                "active"
                                                : undefined
                                        }
                                        id="4"
                                    >
                                        Supera
                                    </RadioButton>
                                </Col>
                            </Row>
                    </RadioGroup>
                </Col>
            </Row>
        </Container>
    )
};

export default CriteriaComponent
