import React from 'react';
import { Route, Switch } from 'react-router-dom'

import { HomePage, LoginPage } from '../pages'

import HeaderContainer from '../containers/header/';
import ProjectsContainer from "../containers/projects/";
import ProjectContainer from "../containers/project/";

import {FaExclamationTriangle} from 'react-icons/fa'
import Loader from 'react-loader-spinner';


export default function PrimaryLayout(props){
    


 const [defaultIndex, setDefaultIndex] = React.useState(0);

 console.log(defaultIndex)


        return (    
            <div className="main-container">
                <HeaderContainer/>
                <main>
                    {props.appFetching === true || !(props.validated ===  true || (props.cookie === undefined)) ? (
                        <div style={{
                            height: "calc(100% - 275px)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <Loader
                                type="Oval"
                                color="#C1D532"
                                height={100}
                                width={100}
                            />
                        </div>
                    ) : props.appError === true ?
                        (
                            <span
                                style={{
                                    marginTop: 40,
                                    backgroundColor: 'tomato',
                                    color: '#f2f2f2',
                                    display: 'flex',
                                    padding: '15px 30px',
                                    fontFamily:"century gothic bold, sans-serif",
                                    borderRadius:'4px',
                                    alignItems: 'center'
                                }}
                            >
                                <FaExclamationTriangle style={{marginRight: 30, fontSize: 27}}/>
                                Erro de conexão, atualize a página e tente novamente.
                            </span>
                        )
                        : (
                            <Switch>
                                <Route exact path='/projeto/:categoria/:projeto' render={() => <ProjectContainer/>} />
                                <Route exact path='/projetos' render={() => <ProjectsContainer defaultIndex={defaultIndex} setDefaultIndex={setDefaultIndex}/>} />
                                <Route exact path='/:path(|inicio)' render={() => <HomePage/>} />
                                <Route exact path='/entrar' render={() => <LoginPage/>}/>
                            </Switch>
                        )
                    }
                </main>
            </div>
        );
 
}
