import React from 'react';
import Cookies from 'universal-cookie';

import {FaSignOutAlt} from "react-icons/fa";
import './user-info.component.styles.sass'

const cookies = new Cookies();

const UserInfoComponent = (props) => (
    <div className='user-info-container'>
        <div>
            <img src={props.img} alt={props.name}/>
        </div>
        <div>
            Bem vindo! <br />
            <span className='user-info-text'>{props.name}</span> <br />
            <button
                onClick={() => {
                    cookies.remove('keyLogin', { path: '/' });
                    props.resetStore();
                    props.logoff();
                }}
                style={{
                    fontFamily: 'Edenred',
                    border: 'none',
                    background: 'transparent',
                    color: '#ef3135',
                    display: 'flex',
                    height: '20px',
                    alignItems: 'center',
                    cursor: 'pointer'
                }}>
                <FaSignOutAlt style={{
                    marginRight: 5
                }}/>
                Sair
            </button>
        </div>
    </div>
);
export default UserInfoComponent;
