import Axios from 'axios';

export const

                REQUEST_LOGIN = 'REQUEST_LOGIN',
                REQUEST_LOGIN_FULFILLED = 'REQUEST_LOGIN_FULFILLED',
                REQUEST_LOGIN_PENDING = 'REQUEST_LOGIN_PENDING',
                REQUEST_LOGIN_REJECTED = 'REQUEST_LOGIN_REJECTED',
                VALIDATE_KEY_LOGIN =  'VALIDATE_KEY_LOGIN',
                VALIDATE_KEY_LOGIN_PENDING = 'VALIDATE_KEY_LOGIN_PENDING',
                VALIDATE_KEY_LOGIN_FULFILLED = 'VALIDATE_KEY_LOGIN_FULFILLED',
                VALIDATE_KEY_LOGIN_REJECTED = 'VALIDATE_KEY_LOGIN_REJECTED',
                LOGOFF = 'LOGOFF',
                LOAD_PROJECTS = 'LOAD_PROJECTS',
                LOAD_PROJECTS_FULFILLED = 'LOAD_PROJECTS_FULFILLED',
                LOAD_PROJECTS_PENDING = 'LOAD_PROJECTS_PENDING',
                LOAD_PROJECTS_REJECTED = 'LOAD_PROJECTS_REJECTED',
                SEND_VOTE = 'SEND_VOTE',
                SEND_VOTE_PENDING = 'SEND_VOTE_PENDING',
                SEND_VOTE_REJECTED = 'SEND_VOTE_REJECTED',
                SEND_VOTE_FULFILLED = 'SEND_VOTE_FULFILLED',
                SET_PROJECT_VOTE = 'SET_PROJECT_VOTE',
                TOGGLE_MODAL = 'TOGGLE_MODAL',
               RESET_STORE = 'RESET_STORE';

const actions = {
    resetStore: () => ({
        type: RESET_STORE,
    }),
    toggleModal: (group, projeto, status) => ({
        type: TOGGLE_MODAL,
        payload: {
            group, projeto, status
        }
    }),
    setProjectVote: (group, projeto, voto) => ({
        type: SET_PROJECT_VOTE,
        payload: {
            group, projeto, voto
        }
    }),
    sendVote: (keyLogin, keyAcess, codigoProjeto, criterios, group, projeto) => {
        const data = {
            putVoto: true,
            codigoProjeto,
            keyLogin,
            keyAcess,
            ...criterios
        };

        return {
            type: SEND_VOTE,
            payload: new Axios({
                params: {
                    codigoProjeto,
                    group: group.type,
                    projeto: projeto.id_projetos
                },
                method: 'post',
                url: '//ws.eward.com.br',
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                data
            })
        }
    },
    loadProjects: (keyLogin, keyAcess) => {
        const data = {
            getProjetos: true,
            keyLogin, keyAcess
        };
        return {
            type: LOAD_PROJECTS,
            payload: new Axios({
                method: 'post',
                url: '//ws.eward.com.br',
                headers: {
                    'Content-type': 'application/json'
                },
                data
            })
        }
    },
    logoff: () => ({
        type: LOGOFF
    }),
    validateKeyLogin: (keyLogin, keyAcess) => {
        const data = {
            checarLogin: true,
            keyLogin,
            keyAcess
        };
        return {
            type: VALIDATE_KEY_LOGIN,
            payload: new Axios({
                method: 'post',
                url: '//ws.eward.com.br',
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                data
            })
        }
    },
    requestLogin: (user, pass, key) => {
        const data = {
            efetuarLogin: "true",
            emailLogin: user,
            senhaLogin: pass,
            keyAcess: key
        };
        return {
            type: REQUEST_LOGIN,
            payload: Axios({
                method: 'post',
                url: '//ws.eward.com.br',
                headers: {
                    'Content-type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                data
            })
        }
    }
};

export default actions;
