import React from 'react';
import {withRouter, Link} from 'react-router-dom'
import { connect } from 'react-redux';
import actions from '../../actions'

import './header.container.styles.sass'

import logoSmall from  '../../assets/logo-small.png'

import UserInfoComponent from "../../components/user-info/";
import {FullWidthContainer, HalfWidthContainer} from '../../components/utils';

class HeaderContainer extends React.Component {
    render() {
        return (
            <FullWidthContainer containerWidth={1200} className='header-container'>
                {this.props.auth === true ? (
                    <UserInfoComponent resetStore={this.props.resetStore} img={this.props.img} logoff={this.props.logoff} name={this.props.name} />
                ) : null}
                <HalfWidthContainer containerWidth={1200} direction='left' className='logo-container'>
                    <Link to="/inicio">
                        <img src={logoSmall} alt='alt-padrao' />
                    </Link>
                </HalfWidthContainer>
            </FullWidthContainer>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    logoff: () => {
        dispatch(actions.logoff())
    },
    resetStore: () => {
        dispatch(actions.resetStore())
    }
});

const mapStateToProps = (state) => ({
    auth: state.control.auth,
    name: state.data.user.name,
    img: state.data.user.img
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderContainer));
