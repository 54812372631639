import React from 'react';
import {useWindowDimensions} from '../../utils'

const HalfWidthContainer = (props) => {
    const { width } = useWindowDimensions();

    const mainContainer = {
        width: width > props.containerWidth ? 'fit-content' : '100%',
        minWidth:  width > props.containerWidth ? `calc(50vw - ${props.containerWidth/2}px + ${props.width !== undefined ? props.width : null})` : null,
        left: props.direction === 'left' ? width > props.containerWidth ? `calc(${props.containerWidth/2}px - 50vw)` : '0' : null,
        right: props.direction === 'right' ? width > props.containerWidth ? `calc(${props.containerWidth/2}px - 50vw)` : '0' : null,
        float: props.direction === 'right' ? 'right' : 'left',
        position: 'relative'
    };

    const childContainer = {
        marginLeft: props.direction === 'left' ? width > props.containerWidth ? `calc(50vw - ${props.containerWidth/2}px)` : '0' : null,
        marginRight: props.direction === 'right' ? width > props.containerWidth ? `calc(50vw - ${props.containerWidth/2}px)` : '0' : null,
        position: 'relative'
    };

    return (
        <div>
            <div className={`${props.className}-parent`} style={mainContainer}>
                <div className={props.className} style={childContainer}>
                    {props.children}
                    <div style={{clear:'both'}} />
                </div>
            </div>
            <div style={{clear:'both'}} />
        </div>
    )
};

export default HalfWidthContainer