import React from 'react';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';

import './app.container.styles.sass'
import {PrimaryLayout} from '../../layouts/'
import actions from '../../actions'

const cookies = new Cookies();

class AppContainer extends React.Component {
    UNSAFE_componentWillMount () {
        if(this.getCookie() !== undefined)
            this.props.validateKeyLogin(this.getCookie() , this.props.keyLogin);
    }
    getCookie(){
        return cookies.get('keyLogin');
    }
    render() {
        return (
            <div className='app-container'>
                <PrimaryLayout cookie={this.getCookie()} appError={this.props.appError} validated={this.props.validated} appFetching={this.props.appFetching}/>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    validateKeyLogin: (keyLogin, keyAccess) => {
        dispatch(actions.validateKeyLogin(keyLogin, keyAccess))
    }
});

const mapStateToProps = (state) => ({
    appError: state.ui.app.error,
    keyLogin: state.control.key,
    appFetching: state.ui.app.fetching,
    auth: state.control.auth,
    validated: state.control.validated
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppContainer);