import React, {Fragment} from 'react';
import { Link } from "react-router-dom";

import Loader from "react-loader-spinner";
import {FaSave, FaExclamationTriangle} from "react-icons/fa";
import { MdHome } from "react-icons/md";
import { hasVotes as checkVotes } from '../../utils'

import CriteriaComponent from "../criteria/";
import './vote.component.styles.sass'
import {SkyLightStateless} from "react-skylight";


const VoteComponent =  (props) => {
    const  { keyLogin, keyAccess, sendVote, projeto: {votos} } = props;
    const hasVotes = checkVotes(votos);

    return (
        <div style={{
            paddingTop: 45,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }} className='vote-component'>
            {Object.entries(votos).map((voto, key) => (
                <CriteriaComponent
                    objectKey={voto[0]}
                    itemSelected={voto[1]}
                    key={key}
                    text={props.criterios[key].text}
                    label={props.criterios[key].label}
                    {...props}
                />
            ))}
            <div           className={"cols"} style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: 30,
                paddingBottom: 30
            }}>
                <div>
                    <Link
                        onClick={() => props.toggleModal(props.group, props.projeto, false)}
                        style={{
                        color: "#666",
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        textAlign: "center"
                    }} to="/projetos">
                        <MdHome style={{marginRight: 5, fontSize: 24}} /> Voltar para lista de projetos
                    </Link>
                </div>
                <div
                    style={{
                        width: "100%",
                        margin: "0 45px",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center"
                    }}>
                    <button
                        style={{
                            maxWidth: "700px",
                            float: "right",
                            margin: 0
                        }}
                        className={props.voteError === true ? "error" : hasVotes === true && props.projeto.saved === false ? "not-saved" : undefined }
                        onClick={(e) =>{
                            sendVote(keyLogin, keyAccess, props.projeto.id_projetos, votos, props.group, props.projeto);
                        }}
                        disabled={!hasVotes}
                    >

                        {props.voteFetching === true ? (
                            <Loader
                                type="ThreeDots"
                                color="#f2f2f2"
                                height={28}
                                width={45}
                            />
                        ) : props.voteError === true && (props._projectCode === props.projeto.id_projetos || props._projectCode === null) ? (
                            <Fragment>
                                <FaExclamationTriangle  style={{marginRight: 10}}/>
                                Erro, tente novamente!
                            </Fragment>
                        ) : (
                            <Fragment>
                                <FaSave style={{marginRight: 10}}/>
                                {hasVotes === true ? props.projeto.saved === true ? "Votação Salva." : "Salvar Votação" :  "Preencha todos os campos"}
                            </Fragment>
                        )
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VoteComponent;
