import React from 'react';

const InputComponent = function(props) {
    const containerStyles = {
        marginBottom: 20
    };

    const inputStyles = {
        width: '100%',
        padding: '7px 15px',
        borderRadius: '30px',
        border: '2px solid #ccc',
        fontSize: '15px',
        fontFamily: 'century gothic bold", sans-serif',
        fontWeight: 600
    };

    return (
        <div style={containerStyles}>
            <input style={inputStyles} {...props}/>
        </div>
    )
};

export default  InputComponent