import React from 'react';
import {connect} from 'react-redux'
import {withRouter, Redirect} from 'react-router-dom'
import actions from '../actions'

import LoginComponent from '../components/login'

class LoginPage extends React.Component {

    render() {
       // / console.log(this.props)
        return (
            <div className="login-page-container">
                {this.props.auth === false ? (
                    <LoginComponent {...this.props}/>
                ) : (
                    <Redirect to="/inicio" />
                )}
            </div>
        );
    }
}


const mapDispatchToProps = dispatch => ({
    requestLogin: (user, pass, key) => {
        dispatch(actions.requestLogin(user,pass,key));
    }
});

const mapStateToProps = (state) => ({
    auth: state.control.auth,
    keyAccess: state.control.key,
    fetching: state.ui.login.fetching,
    fetched: state.ui.login.fetched,
    success: state.ui.login.success
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage));