import Cookies from 'universal-cookie';
import 'babel-polyfill'
import {defaultState, hasVotes} from '../utils';

import {
    RESET_STORE,
    REQUEST_LOGIN_FULFILLED,
    REQUEST_LOGIN_PENDING,
    REQUEST_LOGIN_REJECTED,
    VALIDATE_KEY_LOGIN_FULFILLED,
    VALIDATE_KEY_LOGIN_PENDING,
    VALIDATE_KEY_LOGIN_REJECTED,
    LOGOFF,
    LOAD_PROJECTS_FULFILLED,
    LOAD_PROJECTS_PENDING,
    LOAD_PROJECTS_REJECTED,
    SEND_VOTE_FULFILLED,
    SEND_VOTE_PENDING,
    SEND_VOTE_REJECTED,
    SET_PROJECT_VOTE,
    TOGGLE_MODAL
} from '../actions'

function groupBy(arr, key) {
    var newArr = [],
        types = {},
        i, j, cur;
    for (i = 0, j = arr.length; i < j; i++) {
        cur = arr[i];
        if (!(cur[key] in types)) {
            types[cur[key]] = { type: cur[key], projetos: [] };
            newArr.push(types[cur[key]]);
        }
        types[cur[key]].projetos.push(cur);
    }
    return newArr;
}

const cookies = new Cookies();

const reducers = (state = defaultState, action) => {
    console.log(action)
    switch (action.type) {
        default:
            return {
                ...state,
            };
        case RESET_STORE: {
            return {
                ...state
            }
        }
        case TOGGLE_MODAL: {
            return {
                ...state,
                data: {
                    ...state.data,
                    groups: [
                        ...state.data.groups.filter(group => group.type !== action.payload.group.type),
                        {
                            type: action.payload.group.type,
                            projetos: [
                                ...action.payload.group.projetos.filter(projeto => projeto.id_projetos !== action.payload.projeto.id_projetos),
                                {
                                    ...action.payload.projeto,
                                    modal: action.payload.status,
                                }
                            ]
                        }
                    ]
                }
            }
        }
        case SET_PROJECT_VOTE: {
            const orderedVotes = {};
            const unorderedVotes = {
                ...action.payload.projeto.votos,
                [action.payload.voto.objectKey]: action.payload.voto.value
            };
            Object.keys(unorderedVotes).sort().forEach(function(key) {
                orderedVotes[key] = unorderedVotes[key];
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    groups: [
                        ...state.data.groups.filter(group => group.type !== action.payload.group.type),
                        {
                            type: action.payload.group.type,
                            projetos: [
                                ...action.payload.group.projetos.filter(projeto => projeto.id_projetos !== action.payload.projeto.id_projetos),
                                {
                                    ...action.payload.projeto,
                                    saved: false,
                                    modal: true,
                                    votos: orderedVotes
                                }
                            ]
                        }
                    ]
                }
            }
        }
        case SEND_VOTE_REJECTED: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    votes:{
                        ...state.ui.votes,
                        error: true,
                        fetching: false,
                        fetched: true,
                    }
                }
            }
        }
        case SEND_VOTE_PENDING: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    votes:{
                        ...state.ui.votes,
                        error: false,
                        fetching: true,
                        fetched: false,
                    }
                }
            }
        }
        case SEND_VOTE_FULFILLED: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    votes:{
                        ...state.ui.votes,
                        error: false,
                        fetching: false,
                        fetched: true
                    }
                },
                data: {
                    ...state.data,
                    groups: [
                        ...state.data.groups.filter(group => group.type !== action.payload.config.params.group),
                        {
                            type: action.payload.config.params.group,
                            projetos: [
                                ...state.data.groups.find(group => group.type === action.payload.config.params.group).projetos.filter(projeto => projeto.id_projetos !== action.payload.config.params.projeto),
                                {
                                    ...state.data.groups.find(group => group.type === action.payload.config.params.group).projetos.find(projeto => projeto.id_projetos === action.payload.config.params.projeto),
                                    saved: true,
                                }
                            ]
                        }
                    ]
                }
            }
        }
        case VALIDATE_KEY_LOGIN_REJECTED: {
            return {
                ...state,
                ui: {
                  ...state.ui,
                    app: {
                      ...state.ui.app,
                        fetching: false,
                        error: true
                    }
                },
                control: {
                    ...state.control,
                    auth: false,
                    validated: true
                }
            }
        }
        case LOAD_PROJECTS_FULFILLED: {
            let { projetos } = action.payload.data;
            projetos = projetos.map(projeto => {
                projeto.saved = hasVotes(projeto);
                projeto.modal = false;
                return projeto;
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    groups: groupBy(projetos, 'pro_categoria')
                },
                ui: {
                    ...state.ui,
                    app: {
                        ...state.ui.app,
                        fetching: false,
                        error: false
                    }
                }
            }
        }
        case LOAD_PROJECTS_PENDING: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    app: {
                        ...state.ui.app,
                        fetching: true
                    }
                }
            }
        }
        case LOAD_PROJECTS_REJECTED: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    app: {
                        ...state.ui.app,
                        fetching: false,
                        error: true
                    }
                }
            }
        }
        case LOGOFF: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    login: {
                        ...state.ui.login,
                        fetching: false,
                        fetched: false,
                        success: false
                    }
                },
                control: {
                    ...state.control,
                    auth: false
                },
                data: defaultState.data
            }
        }
        case VALIDATE_KEY_LOGIN_PENDING: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    app: {
                        ...state.ui.app,
                        fetching: true
                    }
                }
            }
        }
        case VALIDATE_KEY_LOGIN_FULFILLED: {
            const { UserData, Login } = action.payload.data;
            return {
                ...state,
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        name: UserData !== undefined ? UserData.jur_nome : state.data.user.name,
                        img: UserData !== undefined ? UserData.pathImg : state.data.user.img
                    }
                },
                control: {
                    ...state.control,
                    auth: Login,
                    validated: true
                },
                ui: {
                    ...state.ui,
                    app: {
                        ...state.ui.app,
                        fetching: false,
                        error: false,
                    }
                }
            }
        }
        case REQUEST_LOGIN_PENDING: {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    login: {
                        ...state.ui.login,
                        fetching: true
                    }
                }
            }
        }
        case REQUEST_LOGIN_FULFILLED: {
            const { Login, keyLogin, UserData } = action.payload.data;
            if(Login) {
                cookies.set('keyLogin', keyLogin, { path: '/' });
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        name: UserData !== undefined ? UserData.jur_nome : state.data.user.name,
                        img: UserData !== undefined ? UserData.pathImg : state.data.user.img,
                    }
                },
                control: {
                    ...state.control,
                    auth: Login,
                    validated: true
                },
                ui: {
                    ...state.ui,
                    login: {
                        ...state.ui.login,
                        fetching: false,
                        fetched: true,
                        success: true
                    }
                }
            }
        }
        case REQUEST_LOGIN_REJECTED:
            return {
                ...state,
                control: {
                    ...state.control,
                    auth: false
                },
                ui: {
                    ...state.ui,
                    login: {
                        ...state.ui.login,
                        fetching: false,
                        fetched: true,
                        success: false
                    }
                }
            }
    }
};

export default reducers
