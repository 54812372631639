import React from 'react'
import {Link} from "react-router-dom";
import encodeurl from "encodeurl";
import {SkyLightStateless} from "react-skylight";
import VoteContainer from "../../containers/vote";
import {hasVotes as checkVotes} from "../../utils";
import { MdCheckCircle, MdRadioButtonUnchecked} from "react-icons/md";
export default function ProjectListComponent (props) {
    const {group, projeto} = props;
    const hasVotes = checkVotes(projeto.votos);
    return (
        <li>
            <div>
                <Link to={encodeurl(`projeto/${projeto.categoriaUrl}/${projeto.nomeUrl}`.split(' ').join('_').toLowerCase())} >
                    {projeto.pro_nome}
                </Link>
            </div>
            <div>
                {!projeto.saved || !hasVotes  ? (
                        <MdRadioButtonUnchecked style={{color:"#f72717"}}/>
                    ) : (
                        <MdCheckCircle style={{color:"#78be2d"}}  />
                )}
            </div>
        </li>
    )
};
