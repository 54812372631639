import React, {Component, Fragment} from 'react';
import VoteComponent from "../../components/vote";

class VoteContainer extends Component {
    render() {
        return (
            <Fragment>
                <VoteComponent {...this.props}/>
            </Fragment>
        );
    }
}

export default VoteContainer;